import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'

const PageTitle = styled.h1`
  ${tw`text-center`}
`

const ColumnWrap = styled.div`
  ${tw`max-w-xl lg:max-w-2xl mx-auto`}
`

const Column = styled.div`
  ${tw`w-full px-10`}
`

const Image = styled.div`
  ${tw`mx-auto mb-10`}
  max-width: 320px;
  .gatsby-image-wrapper {
    border-radius: 9999px;
  }
`

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query AboutQuery {
      prismicAbout {
        data {
          content {
            html
          }
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 420, maxHeight: 420) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  `)
  const { content, image } = data.prismicAbout.data
  return (
    <Layout>
      <SEO title="About" />
      <PageTitle>About</PageTitle>
      <ColumnWrap>
        <Image>
          <Img fluid={image.localFile.childImageSharp.fluid} alt={image.alt} />
        </Image>
        <Column dangerouslySetInnerHTML={{ __html: content.html }} />
      </ColumnWrap>
    </Layout>
  )
}

export default AboutPage
